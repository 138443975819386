import gql from "graphql-tag";
import { pathGroupFields } from "../path-group/path-group.query";
import { pathFields } from "../path/path.query";

// FIXME: compile to "native" gql when posible
// https://github.com/gajus/babel-plugin-graphql-tag
// thats very sad https://github.com/apollographql/graphql-tag/issues/59
// https://github.com/apollographql/graphql-tag/issues/42
// const query = require('gql/Query.gql');

export const userFields = gql`
  fragment userFields on User {
    id
    activePathId
    clientId
    actionPointsTotal
    firstName
    lastName
    timezone
    imageUrl
    advanceColour
    email
    managerEmail
    accountabilityEmails
    cohortId
    ssoProvider
    isSso
    profileUploadedDay
    managerAddedDay
    userBgColor
  }
`;

export const pathRegistrationFields = gql`
  fragment pathRegistrationFields on PathRegistration {
    userId
    pathId
    earnedActionPoints
    pathData {
      loginReminderEnabled
      loginReminderTime
      deviceRegistrationId
      emailReminderEnabled
      pushReminderEnabled
      socialReminderEnabled
      socialEmailEnabled
      selectedLearningDay
    }
    screensViewed
    scheduleDay
    loggedInDates
    selectedDayPart
    userBgColor
  }
`;

export const updateUser = gql`
  mutation updateUser($user: UserInput!) {
    updateUser(userInput: $user) {
      ...userFields
    }
  }
  ${userFields}
`;

export const updatePathRegistration = gql`
  mutation updatePathRegistration($pathRegistration: PathRegistrationInput!) {
    updatePathRegistration(pathRegistrationInput: $pathRegistration) {
      ...pathRegistrationFields
    }
  }
  ${pathRegistrationFields}
`;

export const signUpUser = gql`
  mutation signUpByForm($user: UserInput!) {
    signUpByForm(userInput: $user) {
      user {
        ...userFields
      }
      errors
    }
  }
  ${userFields}
`;

export const pathGroupsByClientEmail = gql`
  query pathGroupsByClientEmail(
    $email: String!
    $clientId: String
    $clientSubDomain: String
    $fromMobile: Boolean
  ) {
    pathGroupsByClientEmail(
      email: $email
      clientId: $clientId
      clientSubDomain: $clientSubDomain
      fromMobile: $fromMobile
    ) {
      pathGroups {
        ...pathGroupFields
      }
      errors
    }
  }
  ${pathGroupFields}
`;

export const pathsBySignupCode = gql`
  query pathsBySignupCode(
    $signupCode: String!
  ) {
    pathsBySignupCode(
      signupCode: $signupCode
    ) {
      paths {
        id
        name
        pathGroup {
          id
          name
          client {
            id
            name
          }
        }
      }
      errors
    }
  }
`;

export const pathGroupsBySignupCode = gql`
  query pathGroupsBySignupCode(
    $signupCode: String!
  ) {
    pathGroupsBySignupCode(
      signupCode: $signupCode
    ) {
      pathGroups {
        ...pathGroupFields
      }
      errors
    }
  }
  ${pathGroupFields}
`;

export const pathGroupsByToken = gql`
  query pathGroupsByToken($token: String!) {
    pathGroupsByToken(token: $token) {
      ...pathGroupFields
    }
  }
  ${pathGroupFields}
`;

export const findUser = gql`
  query findUser($id: Int!) {
    user(id: $id) {
      #    createdAt
      ...userFields
    }
  }
  ${userFields}
`;

export const listUsers = gql`
  query listUsers($email: String!) {
    userEmail(email: $email) {
      ...userFields
      createdAt
    }
  }
  ${userFields}
`;

export const deleteAccount = gql`
mutation deleteAccount($id: Int!) {
 deleteAccount(id: $id) {
    status
  }
}
`


export const screenViewedFields = gql`
  fragment pathRegistrationFields on PathRegistration {
    userId
    pathId
    screensViewed
    scheduleDay
  }
`;


export const updateScreensViewed = gql`
  mutation updatePathRegistration($pathRegistration: PathRegistrationInput!) {
    updatePathRegistration(pathRegistrationInput: $pathRegistration) {
      ...pathRegistrationFields
    }
  }
  ${screenViewedFields}
`;
