import {
  Component,
  Input,
  ViewChild,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  AfterViewInit,
  ChangeDetectorRef,
  DoCheck,
  OnDestroy,
  Renderer2,
  HostListener} from '@angular/core'
import { Question } from '../question/question.model'
import { Reply } from '../reply/reply.model'
import { QuestionService } from '../question/question.service'
import { ReplyService } from '../reply/reply.service'
import { ReplyComponent } from '../reply/reply.component'
import { AttachmentUploaderComponent } from '../shared/attachment-uploader/attachment-uploader.component'
import { ToastrService } from 'ngx-toastr'
import { TranslationService } from '../translation/translation.service'
import { AppState } from '../app.service'
import { Path } from '../path/path.model'
import { Router } from '@angular/router'
import { Apollo } from 'apollo-angular'
import { CordovaService } from '../cordova.service'
import { I18n } from '../i18n.service'
import { SessionService } from '../session/session.service'
import { MobileChatServiceService } from '../pages/mobile-chat-board/mobile-chat-service.service'
import { RoutineV2Service } from '../routine-v2/routine-v2.service'
import { ToasterService } from '../toaster.service'
import { User } from '../user/user.model'
import { ModalController, Platform } from '@ionic/angular'
import { CommentListModalComponent } from '../comment-list-modal/comment-list-modal.component'
import { SpinnerService } from '../spinner.service'
import { HomeUiService } from '../new-design/home-ui.service';
import mixpanel from 'mixpanel-browser'
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx'

@Component({
  selector: 'first90-question-v2',
  templateUrl: './question-v2.component.html',
  styleUrls: ['./question-v2.component.scss'],
})
export class QuestionV2Component implements OnInit, OnChanges, AfterViewInit, DoCheck, OnDestroy {
  @Input() question: Question
  @Input() mode: string = 'question'
  @Input() isPreview: boolean
  @Input() inverted: boolean = false
  @Input() small = false

  @ViewChild('uploader') uploader: AttachmentUploaderComponent
  @ViewChild('replyBlock') replyBlock: ElementRef
  @ViewChild('repliesContainer') repliesContainer: ElementRef

  path: Path
  user: User
  ishideComment:boolean = true;
  sortBy: string = 'timestamp'
  replyText: string = ''
  cachedReplyText: string = ''  // LUMBA-1383
  attachmentUrl: string = null
  errors: { answerError: false }
  replyTo: Reply = null
  replyToComponent: ReplyComponent
  replyToId: number
  isLoading: boolean = false
  replyOffset: number
  tagName_id: number
  view:any
  public replies: Reply[]
  public repliesTree: any[]=[]
  isTextAdded: boolean = false
  isMobileWebIos = false;
  isMobileWeb=false;
  modalDataResponse: any;
  page;
  apiReplyCount = 0;
  replyCount = 0;
  isMobile
  isKeyboardVisible=false
  constructor(
    private i18n: I18n,
    private apollo: Apollo,
    private router: Router,
    private appState: AppState,
    private toastrService: ToasterService,
    private questionService: QuestionService,
    private replyService: ReplyService,
    private translationService: TranslationService,
    private cordovaService: CordovaService,
    private sessionService: SessionService,
    private routineV2Service: RoutineV2Service,
    private mobileService: MobileChatServiceService,
    private cdRef: ChangeDetectorRef, // LUMBA-1325
    public modalCtrl: ModalController,
    private keyboard: Keyboard,
    private platform: Platform,
    private spinnerService: SpinnerService,
    public renderer: Renderer2,
    public homeUIService: HomeUiService,
  ) {
    this.path = this.appState.get('currentPath')
    this.user = this.appState.get('currentUser')
    this.page = 1
    this.isMobile = this.appState.get('isMobile');
  }

  ngOnInit() {
    // LUMBA-1317
    this.isMobileWeb = this.appState.get('MobileWeb')
    this.isMobileWebIos = this.appState.get('MobileWebIos');
    this.toggleSort('timestamp')
    this.appState.set("mode", "question"); //mode is set for particular SHARE YOUR THOUGHT
    this.platform.ready().then(() => {
     this.keyboard
        .onKeyboardWillShow()
        .subscribe(() => {
          this.isKeyboardVisible = true;
        });

     this.keyboard
        .onKeyboardWillHide()
        .subscribe(() => {
          this.isKeyboardVisible = false;
        });
    });
   // window.addEventListener('scroll', this.onScroll , true); //third parameter
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }

  ngAfterViewInit() {
    this.getQuestionDetails();
    this.replyOffset = this.replyBlock.nativeElement.offsetTop
  }
   ngOnDestroy() {
    this.removeInputEventListeners();
  
  }

  addInputEventListeners() {
    const inputs = document.querySelector("textarea");
    if (inputs) {
       this.renderer.listen(inputs, "focus", () => {
         this.isKeyboardVisible = true;
       });

       this.renderer.listen(inputs, "blur", () => {
         this.isKeyboardVisible = false;
       });
       this.renderer.listen(inputs, "focusout", () => {
         this.isKeyboardVisible = false;
       });
    }
   
  }

  removeInputEventListeners() {
    const inputs = document.querySelector("textarea");
    if (inputs) {
       this.renderer.listen(inputs, "focus", null);
       this.renderer.listen(inputs, "blur", null);
       this.renderer.listen(inputs, "focusout", null);
    }
   
  }

  async ngDoCheck() {
    let isCommentDeleted = this.appState.get('refreshCommentList')    
    if (isCommentDeleted) {
      this.page = 1;
      this.appState.set('refreshCommentList', undefined)
      await this.getQuestionDetails(null, true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.question || changes.question.firstChange) {
      return false
    }
    this.question.sortRepliesBy(this.sortBy)
    this.cdRef.detectChanges()
  }

  toggleSort(by: string) {
    console.log("by:" + by);
    this.sortBy = by
    this.question.sortRepliesBy(by)
  }

 HideComment(){
      this.ishideComment=false
  }

 ShowComment(){
      this.ishideComment=true
  }
  orderingSubReplies(reply) {
    var level1 = [reply, ...this.repliesTree];
    let replySort;
    if (level1) {
      var subReply = level1.filter(item => item.repliableId)
      level1?.forEach(reply => {
        subReply?.forEach(element => {
          if (element?.repliableId === reply.id) {
            reply.replies =[element, ...reply.replies]
            //replySort = reply;
          }
          reply.timestamp = new Date(reply.createdAt)
        });
      });
    // if (replySort) {
    //     replySort.timestamp = new Date(reply.createdAt);
    //   }
    //  let index =level1?.findIndex((reply) => reply.id == replySort.id)
    //   level1.splice(index,1)
      let repliesTree = level1.filter(reply => reply.id && reply.level === 1)
      // this.repliesTree = [replySort, ...repliesTree];
      this.repliesTree = repliesTree;
    }
  }
  reply() {
    mixpanel.track('share thoughts', {'thoughts': this.replyText})
    if(this.replyText.length==0){
      this.toastrService.error(this.i18n.t('Please enter a comment'))
      return false;
    }

    var list_of_tags_ids = []

    list_of_tags_ids = this.sessionService.getUserTAGs(this.replyText,this.complexItems)

    // if (!this.replyText || this.replyText.length < 3) {
    //   this.toastrService.error(this.i18n.t('Comment is too short'), this.i18n.t('Error'))
    //   return false
    // }
    // if (this.isPreview) {
    //   return false
    // }
    // if (this.isLoading) {
    //   return false
    // }
    this.isLoading = true

    this.unloadReply()
    if (this.replyTo) {
      // this.spinnerService.on()
      this.replyService
        .reply(
          this.question,
          this.replyTo.id,
          this.cachedReplyText,
          this.attachmentUrl,
          list_of_tags_ids,
          this.question.id,
          this.mode,
          
        )
        .subscribe(
          (reply) => {
          console.log('reply :', reply);
            this.appState.set('isSubReply', true)
            this.ishideComment = true;
            this.reset();
            this.isLoading = false;
            this.isTextAdded = false;
            this.toastrService.success(
              this.i18n.t("Comment posted!")
            );
            // this.spinnerService.off()
            this.loadReply();
            this.orderingSubReplies(reply);
            this.isLoading = true;
            // this.getQuestionDetails();
            this.cdRef.detectChanges();
            // if (this.repliesContainer != undefined) {
            //   this.repliesContainer.nativeElement.scrollIntoView();
            //   this.repliesContainer.nativeElement.scrollTop = 0;
            // }
          },
          (error) => {
            this.toastrService.error(
              this.i18n.t("Comment not posted. Check your connection and try reloading the app."));
            this.isLoading = false;
            // this.spinnerService.off()
            this.loadReply();
            this.cdRef.detectChanges();
          }
        );
      this.loadReply();
      this.cdRef.detectChanges();
    } else {
      // this.spinnerService.on()
      this.questionService
        .replyQuestion(this.question, this.cachedReplyText, this.attachmentUrl,list_of_tags_ids, 'question')
        .subscribe((reply) => {
          this.appState.set('currentActionPoints', reply?.data?.replyQuestion?.actionPoints)
          // this.spinnerService.off()
          this.appState.get('currentPath').update('questions', this.question.id, {isCompleted: true})           
          this.ishideComment = true;
          this.reset()
          this.isLoading = false
          this.isTextAdded = false;
          this.loadReply()
          // this.getQuestionDetails()
          this.orderingSubReplies(reply.data.replyQuestion.reply);
          this.isLoading = true;
          this.toastrService.success(this.i18n.t('Comment posted!'))
          if(this.repliesContainer!= undefined){
            this.repliesContainer.nativeElement.scrollIntoView({ behavior: "smooth", block: "nearest" })
           // this.repliesContainer.nativeElement.scrollTop = 0
          }
          this.cdRef.detectChanges()
          

        }, error => {
          // this.spinnerService.off()
          console.log(error)
          this.loadReply()
          this.toastrService.error(this.i18n.t('Comment not posted. Check your connection and try reloading the app.'))
          this.isLoading = false
          this.cdRef.detectChanges()
        })
    }

    SessionService.setData(0)

  }

  translate($event){
    let toggleEnabled = $event.detail.checked
    console.log('toggleEnabled :', toggleEnabled);

    if (toggleEnabled === true) {
      console.log('repliesTree before translation:', this.repliesTree);
      const path: Path = this.appState.get("currentPath");
      const repliesIds = this.repliesTree.map((reply) => reply.id);
      this.translationService
        .loadRepliesTranslations(repliesIds, path.language)
        .subscribe((translations) => {
          this.repliesTree.forEach((reply) => {
            const key = `Reply:${reply.id}`;
            reply.text = translations[key].text;
            this.cdRef.detectChanges()
          });
        });
    }

    if (toggleEnabled === false) {
      this.appState.set('commentDeleted', true)
      this.cdRef.detectChanges()
    }
  }

  reset() {
    this.replyText = ''
    this.attachmentUrl = null
    if (this.uploader) {
      this.uploader.reset()
    }
    this.cachedReplyText = ''
  }

  // LUMBA-1383
  loadReply() {
    if (this.cachedReplyText) {
      this.replyText = this.cachedReplyText
      this.cachedReplyText = ''
    }
  }

  // LUMBA-1383
  unloadReply() {
    this.cachedReplyText = this.replyText
    this.replyText = ''
  }

  setReplyTo(replyComponent: ReplyComponent) {
    if (this.uploader) {
      this.uploader.reset()
    }
    // This code was needed when all replies was visible
    // Now all replies hidden except selected for reply
    if (this.replyToComponent) {
      this.replyToComponent.selected = false
    }
    this.replyToComponent = replyComponent
    if (replyComponent) {
      this.replyTo = replyComponent.reply
      this.replyToId = this.replyTo.id
    } else {
      this.replyTo = null
      this.replyToId = null
    }
  }

  refresh() {
    this.apollo.getClient().clearStore()
    this.appState.refreshMainComponent.emit()
  }

  onTextChange(event) {
    if (event?.trim()?.length > 0)
    {
      this.isTextAdded = true
    }
    else {
      this.isTextAdded = false;
    }
  }
  scrollTo(event) {
    if (this.cordovaService.onCordova) {
      setTimeout(() => {
        window.scrollTo({top: this.replyBlock.nativeElement.offsetTop - 160, behavior: 'smooth'})
      }, 10)
    }
  }

  complexItems = this.appState.get("userlist")

  namesData : any[]

  format(item:any) {
    var data = item['id']
    setTimeout(() => {
      SessionService.setData(data)
    }, 100);
    return item['username'].toUpperCase()
  }

  mentionConfig:any = {
    mentions: [
      {
        items: this.complexItems,
        labelKey: 'username',
        mentionSelect: this.format,
        dropUp:true
      }
    ]
  };

  translatePost(language: string,text: any){

    let data = {
      language: language,
      text: text
    }

    this.mobileService.translateContent(data).subscribe(
      (response) => {
        if(response.status){
          this.replyText = response.data.text
          this.cdRef.detectChanges()
          this.toastrService.success(this.i18n.t('Post translated successfully!'))
        }
      },
    (error) => {
      this.toastrService.error(this.i18n.t('Text translation failed!'))
      console.log("TranslationService data ",error);
    }
    )
  }
  isKeyboardOpened(ev) {
    if (this.isMobile) {
      this.isKeyboardVisible = true;
    }    
  }
  doInfinite(event) {
    console.log('doInfinite :');
    this.getQuestionDetails(event);
  }

  async getQuestionDetails(event=null,isPostDeleted=false): Promise<any>{
     
    var mode = this.capitalizefirstletter(this.mode);
    return new Promise( async (resolve, reject) => {
    try{
      const data = {
        id : this.question.id,
        path_id : this.path.id,
        mode: mode,
        page:this.page
      }

      await this.routineV2Service.getAllPostsRepliyDetails(data).subscribe(
        (response) => {
          this.appState.set('isQuestionCompleted', response.data.isCompleted)
            var level1;
             if(this.page ==1 || isPostDeleted) {
                this.apiReplyCount = 0;
               this.repliesTree = [];
             }
            if (this.page == 1 && response.data.replies?.length > 0) {
              this.repliesTree = [];
              level1 = response.data.replies;
            }
            else if (response.data.replies?.length > 0) {
              level1 = this.repliesTree.concat(response.data.replies);
            }
          this.replyCount = response.data.reply_count;
           this.apiReplyCount = this.apiReplyCount + response.data.replies?.length;
          if (level1) {
            var subReply = level1.filter(item => item.repliableId)
              
            level1.forEach(reply => {
              subReply.forEach(element => {
                if (element.repliableId === reply.id) {
                  reply.replies.push(element)
                }
                reply.timestamp = new Date(reply.createdAt)
              });
            }); 
            this.repliesTree = level1.filter(reply => reply.id && reply.level === 1)
            // if (this.page == 1) {
            //   this.repliesTree= this.repliesTree.concat(repliesTree)
            // }
            // else {
            //    this.repliesTree= this.repliesTree.concat(repliesTree);
            // }
            this.page = this.page + 1
            if (event) {
              event.target.complete();
            }
             this.cdRef.detectChanges();
            console.log("repliescount", this.repliesTree)
            this.appState.set('repliesLength', this.repliesTree.length) 
            this.cdRef.detectChanges();
        }
       
      },

       (error) => {
        console.log('error',error)
        this.toastrService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
      }
      )
      resolve(true);
    }catch (error){
      console.log('error',error)
      reject(error);
        this.toastrService.error(this.i18n.t("Server or connection issue. Please check your internet connection."))
    }
  });
  } 

  capitalizefirstletter(word: string){
    return word.charAt(0).toUpperCase() + word.slice(1)
  }

  async initModal() {
    var id = this.question.id
    this.appState.set('question',this.question)
    const modal = await this.modalCtrl.create({
      component: CommentListModalComponent,
      componentProps: {
        question: this.question.id
      }
    });

    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.modalDataResponse = modalDataResponse.data;
      }
    });

    return await modal.present();
  }
  @HostListener('click', ['$event'])
  onLinkClick(e) {
    this.addInputEventListeners();
  }
  @HostListener('touchend', ['$event'])
  onTouchEnd(event: TouchEvent) {
    this.addInputEventListeners();
  }
}
