import { Base } from '../shared/base'

// LUMBA-982
export interface SignUpForm {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  passwordConfirmation: string,
  timezone: string,
  confirm: boolean,
  activePathId?: number,
  cohortId?: number,
  signupCode: string,
}

export class User extends Base {
  //
  init() {
    this._fields = ['id', 'activePathId', 'clientId', 'actionPointsTotal', 'firstName', 'lastName', 'advanceColour', 'email',
    'imageUrl', 'accessLevel', 'actionPoints', 'authorizationToken', 'password','managerEmailCheck','accountabilityCheck','managerEmail','managerAddedDay',
    'passwordConfirmation','signupCode', 'accessLevel', 'timezone', 'enabled','accountabilityEmails', 'createdAt', 'cohortId', 'ssoProvider', 'isSso','profileUploadedDay', 'userBgColor']

    this._defaultInput = { exclude: ['actionPointsTotal'] }
  }

  public id: number
  public actionPointsTotal: number
  public activePathId: number
  public clientId: number
  public firstName: string
  public lastName: string
  public email: string
  public advanceColour: string
  public imageUrl: string
  public accessLevel: string
  public actionPoints: number
  public timezone: string
  public authorizationToken: string
  public password: string
  public passwordConfirmation: string
  public signupCode: string 
  public enabled: boolean
  public createdAt: number
  public managerEmailCheck:boolean
  public accountabilityCheck: boolean
  public managerEmail: string
  public accountabilityEmails:any[]
  public cohortId: number
  public ssoProvider: string
  public isSso: boolean
  public profileUploadedDay: number
  public managerAddedDay: number
  public userBgColor: string
  fullName() {
    return this.firstName + ' ' + this.lastName
  }

  nameInitial() {
    if (this.lastName && this.lastName.length > 0) {
      return this.firstName + ' ' + this.lastName[0]
    } else {
      return this.firstName
    }
  }
}
