import { Injectable } from '@angular/core';
import { AppState } from '../app.service';
import { Router } from '@angular/router';
import * as _ from 'underscore';
import { ToasterService } from '../toaster.service';
import { SpinnerService } from '../spinner.service';
import { RoutineV2Service } from '../routine-v2/routine-v2.service';
import { DailyStepsService } from '../daily-steps/daily-steps.service';
import { AlertGiveFeedbackCommitmentComponent } from '../alert-give-feedback-commitment/alert-give-feedback-commitment.component';
import { MatDialog } from '@angular/material/dialog';
import { HomeV5Service } from '../pages/home-v5/home-v5.service';
import { AlertController, AnimationController, ModalController } from '@ionic/angular';
import { ClientService } from '../client/client.service';
import { JournalService } from '../pages/journal/journal.service';
import { LibraryService } from '../my-library/library.service';
import { AnimationsService } from '../shared/animations.service';
import { I18n } from '../i18n.service';
import { FullScreenAudioComponent } from '../full-screen-audio/full-screen-audio.component';
import { FullScreenVideoComponent } from '../full-screen-video/full-screen-video.component';
import { FullScreenDocumentComponent } from '../my-library/full-screen-document.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import mixpanel from 'mixpanel-browser';
import { AlertConfirmNotificationComponent } from '../alert-confirm-notification/alert-confirm-notification.component';
import { AlertReviewRoutineComponent } from '../alert-review-routine/alert-review-routine.component';
import { AlertNotificationUpdateComponent } from '../alert-notification-update/alert-notification-update.component';
import * as moment from 'moment';
import { AlertAnimationComponent } from '../alert-animation/alert-animation.component';
import { WeekendCalendarComponent } from '../routine-v2/weekend-calendar.component';
import { map } from 'rxjs/operators';
import { DailyStepsContentModalComponent } from './daily-steps-content-modal/daily-steps-content-modal.component';
import { AlertUnlockLevelComponent } from '../alert-unlock-level/alert-unlock-level.component';
import { Path } from '../path/path.model';
import { CohortService } from '../cohorts/cohort.service';
import { SurveyService } from '../survey/survey.service';
import { AnalyticsServiceService } from '../services/analytics-service.service';
import { Client } from '../client/client.model';

@Injectable({
  providedIn: 'root'
})
export class HomeUiService {

  videoFormats = ['.mp4', '.mov', '.avi', '.avchd', '.f4v', '.mkv', '.wmv','youtube.com', 'webm', 'html5', 'HTML5', 'watch'];
  audioFormats = [".mp3", 'play'];
  makeHabit: boolean
  isonboardingcompleted: any;
  path:Path;
  isMobile:boolean;
  clientId;
  modalStack =[];
  selectedTimings;
  isDarkTheme: boolean;
  API_URL: string = environment.apiBaseUrl
  UPDATE_DATES = '/users/update_logged_in_dates'
  GET_DAILY_STEPS_STATUS = '/get_daily_steps'
  GET_STEP_ACTION_POINTS_DATA ='/get_step_points'
  counterId;
  currentUser;
  currentClient: Client;
  private apiStepDataSubject = new BehaviorSubject<any>(null);

  get refreshStepApiData() {
    return this.apiStepDataSubject.asObservable();
  }
  constructor(private appState: AppState, 
    private route: Router,
    private spinnerService: SpinnerService,
    private toasterService: ToasterService,
    private routineService: RoutineV2Service,
    private dailyStepService: DailyStepsService,
    private clientService: ClientService,
    private dialog: MatDialog,
    private modalController: ModalController,
    private libraryService: LibraryService,
    private homev5Service: HomeV5Service,
    private journalService: JournalService,
    private alertController: AlertController,
    private i18n: I18n,
    private animationService: AnimationsService,
    private cohortService: CohortService,
    private animationCtrl: AnimationController,
    private analyticsService: AnalyticsServiceService,
    private surveyService: SurveyService,
    private httpclient: HttpClient) { 
    this.isMobile = this.appState.get('isMobile');
      const clientId = localStorage.getItem('clientId')
      this.isDarkTheme = localStorage.getItem('theme') === 'dark';
      if (clientId) {
        this.clientId = clientId
      }
      this.currentUser = this.appState.get('currentUser')
      this.path = this.appState.get('currentPath')

    }
    toggleTheme() {
      console.log('inside toggle')
      this.isDarkTheme = !this.isDarkTheme;
      localStorage.setItem('theme', this.isDarkTheme ? 'dark' : 'light');
      mixpanel.track("Theme Changed", {'Theme': this.isDarkTheme ? 'Light' : 'Dark'})
    }
  
    isDark() {
      // console.log('isdark', this.isDarkTheme )
      return this.isDarkTheme;
    }
  openDocument(post, dialog, journalPathName) {
    console.log('post :', post);
    let doc = this.appState.get('singleDocument');
    if(post?.id == doc?.id) {
      post =this.appState.get('singleDocument')
    }
    this.appState.set('singleDocument', post)
    this.appState.set('journalPost', undefined)
    if (post.isrecommended && post.isrecommended === true) {
      this.appState.set('isCasualDocument', true)
    }
    dialog.closeAll();
    this.appState.set('journalPathName', journalPathName)
    this.appState.set('journalStepNumber', post.startDay)
    const url = post.link;
    if (this.audioFormats.some(el => url.includes(el))) {
      console.log('Audio File',this.appState.get('isMobile'));
      if (this.appState.get('isMobile')) {
        this.openNextComponent(FullScreenAudioComponent, {data:post})
      } else {
        this.openNextComponent(FullScreenAudioComponent, {data:post},'desktop-library-custom')        
      }
    } else if (this.videoFormats.some(el => url.includes(el))) {
      console.log('Video File',this.appState.get('isMobile'));
      if (this.appState.get('isMobile')) {
        this.openNextComponent(FullScreenVideoComponent, {data:post})
      } else {
        this.openNextComponent(FullScreenVideoComponent, {data:post},'desktop-library-custom')
      }

    } else {
      console.log('Document',this.appState.get('isMobile'));
      if (this.appState.get('isMobile')) {
        this.openNextComponent(FullScreenDocumentComponent)
      } else {
        this.openNextComponent(FullScreenDocumentComponent,'','desktop-library-custom')        
      }

    }
  }
  

setModalStack(modal){
  this.modalStack.push(modal);
}
  allpaths(type?) {
    if(!this.clientId){
      this.clientId = localStorage.getItem('clientId');
    }
    this.clientService.getallpath(Number(this.clientId),type)
      .subscribe(client => {
        this.appState.set('allPathCount', client._data['paths']?.length);
        this.appState.set("allPathsData", client._data['paths']);
      })
  }
  async getCohorts() {
    if (!this.clientId){
  this.clientId =localStorage.getItem('clientId')
  }
    if (this.clientId) {
      await this.cohortService.getCohort(Number(this.clientId)).subscribe(cohort => {
      this.appState.set("cohortsData",cohort.data)
      })
    } else {
      console.log('no cohort found')
    } 
  }
 
  getRoutinesByCurrentDay(todoRoutines){
    let levels = []
    let todoLevelsList;
    if (todoRoutines) {
      todoRoutines.forEach(element => {
        element.routine.forEach(element => {
          if (element.routine_description) {
            levels.push(element)
          }
        });
      });

      levels.forEach(element => {
        if(element.is_level_completed === false){
          todoLevelsList = element
        }
      });
      // todoLevelsList = levels
    }
    return todoLevelsList
  }

  getRoutineLevel(todoRoutine){
    let levels = []
    let todoLevelsList;
    if (todoRoutine) {
        todoRoutine?.routine?.forEach(element => {
          if (element.routine_description) {
            levels.push(element)
          }
        });
      levels?.forEach(element => {
        if(element.is_level_completed === false && !todoLevelsList){
          todoLevelsList = element
        }
      });
      // todoLevelsList = levels
    }
    return todoLevelsList
  }

  getCompletedRoutines(todoRoutine){
    let levels = []
    let todoLevelsList;
    if (todoRoutine) {
        todoRoutine?.routine?.forEach(element => {
          if (element.routine_description) {
            levels.push(element)
          }
        });
      levels?.forEach(element => {
        if(!todoLevelsList){
          todoLevelsList = element
        }
      });
    }
    return todoLevelsList
  }

  getTaskDurations(inoutScreens, currentDay) {
    let durationOfTask;
    if (inoutScreens!==null && !_.isUndefined(inoutScreens[currentDay - 1])) {

      if (inoutScreens[currentDay - 1].stime && inoutScreens[currentDay - 1].etime) {
        durationOfTask = inoutScreens[currentDay - 1].stime + " - " + this.appState.get('currentPath').inoutScreens[currentDay - 1].etime;
        return durationOfTask;
      } else if (!inoutScreens[currentDay - 1].stime && inoutScreens[currentDay - 1].etime) {
        durationOfTask = inoutScreens[currentDay - 1].etime;
        return durationOfTask;
      } else {
        return "5 - 10";
      }
    } else {
      return "5 - 10";
    }
  }

  getSingleDoc(post) {
    this.path = this.appState.get('currentPath')
    this.appState.set('singleDocument', post.documents[0])
    this.appState.set('journalPathName', this.path.userGroup.name)
    this.appState.set('journalStepNumber', post.startDay)
    this.appState.set('journalPost', post)
    this.appState.set('singleDocNote', post.text)
    let postData =post?.documents[0];
    postData.pathName=postData?.pathName? postData?.pathName: post.pathName
    postData.text =postData?.text? postData?.text: post.text
    if (postData.length >= 1) {
      const url = postData.link;
      if (this.audioFormats.some(el => url.includes(el))) {
        console.log('Audio File');
        mixpanel.track('Library-saved post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Audio File','post id': post.id})
        if (this.appState.get('isMobile')) {
          this.openNextComponent(FullScreenAudioComponent, { data: postData })
        } else {
          this.openNextComponent(FullScreenAudioComponent, { data: postData },'desktop-library-custom')
        }
      } else if (this.videoFormats.some(el => url.includes(el))) {
        console.log('Video File');
        mixpanel.track('Library-saved post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Video File','post id': post.id})
        if (this.appState.get('isMobile')) {
          this.openNextComponent(FullScreenVideoComponent, { data: postData })
        } else {
          this.openNextComponent(FullScreenVideoComponent, { data: postData },'desktop-library-custom')
        }
      } else {
        console.log('Document');
        mixpanel.track('Library-saved post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Document File','post id': post.id})
        if (this.appState.get('isMobile')) {
          this.openNextComponent(FullScreenDocumentComponent)
        } else {
          this.openNextComponent(FullScreenDocumentComponent,'','desktop-library-custom')
        }
      }
    } else {
      mixpanel.track('Library-saved post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Document File','post id': post.id})
      if (this.appState.get('isMobile')) {
        this.openNextComponent(FullScreenDocumentComponent)
      } else {
        this.openNextComponent(FullScreenDocumentComponent,'','desktop-library-custom')        
      }
    }
  }

  getCasualDoc(post) {
    this.path = this.appState.get('currentPath')
    this.appState.set('journalPost', undefined)
    this.appState.set('singleDocument', post)
    if (post.isrecommended && post.isrecommended === true) {
      this.appState.set('isCasualDocument', true)
      mixpanel.track('Library-tools post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Casual Document', 'post id': post.id})
    }
    this.appState.set('journalPathName', this.path.userGroup.name)
    this.appState.set('journalStepNumber', post.startDay)
    const url = post.link;
    if (this.audioFormats.some(el => url.includes(el))) {
      console.log('Audio File',post);
      mixpanel.track('Library-tools post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Audio File','post id': post.id})
      if (this.appState.get('isMobile')) {
        this.openNextComponent(FullScreenAudioComponent, {data:post})        
      } else {
        this.openNextComponent(FullScreenAudioComponent, {data:post},'desktop-library-custom')
      }
    } else if (this.videoFormats.some(el => url.includes(el))) {
      console.log('Video File',post);
      mixpanel.track('Library-tools post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Video File','post id': post.id})
      if (this.appState.get('isMobile')) {
        this.openNextComponent(FullScreenVideoComponent, {data:post})
      } else {
        this.openNextComponent(FullScreenVideoComponent, {data:post},'desktop-library-custom')
      }
    } else {
      console.log('Document');
      if (this.appState.get('isMobile')) {
        this.openNextComponent(FullScreenDocumentComponent)
      } else {
        this.openNextComponent(FullScreenDocumentComponent,'','desktop-library-custom')        
      }
    }

  }
  getRoutineByLevel(subItem, todoRoutines){
    let currentRoutine
    if (todoRoutines) {
      todoRoutines.forEach(element => {
        if (subItem.routine_info_id === element.routine_info_id) {
          currentRoutine = element
        }
      });
      return currentRoutine
    }
  }

  getReps(item) {
    return item.reps_commit
  }

  logaRep(routine: any, level: any, index: any, currentUser: any, currentDay: number, currentPath: any, modal: any=null) {
    let repsCommitArray = routine.routine.filter(item => item.reps_commit)
    let repsArray = repsCommitArray.map(this.getReps)
    this.isMobile = this.appState.get('isMobile');
    let totalRepsArray = Array.prototype.concat.apply([], repsArray);
    let totalLevelRepsArray = level.reps_commit.filter(item => item.is_completed === false)

    if (level.is_unlocked === false) {
      this.toasterService.warning(this.i18n.t('Please complete previous level.'))
    } else {
      this.spinnerService.on();

      const requestData = {
        'user_id': currentUser.id,
        'path_id': routine.path_id,
        'routine_info_id': routine.routine_info_id,
        'level_id': level.id,
        'schedule_day': currentDay,
        'feedback': localStorage.getItem('repLogText'),
        'step': index + 1
      }

      this.routineService.replog(requestData).subscribe(
        (response) => {
          let object = null
          this.spinnerService.off();
          // add reply to the local path routine_info
          if (response && response.data && response.data.routine_info_id) {
            object = this.appState.get('currentPath').routineInfo.find(q => q.id === response.data.routine_info_id)
          }

          this.toasterService.success(this.i18n.t('Rep Logged!'))
          this.dailyStepService.getActions(currentUser.id, routine.path_id)
          let indexOfLevel = this.appState.get('indexOfLevel')
          var isLastRepLogged = level.reps_commit.filter(item => item.is_completed === true)
          this.appState.set('isTodoReps', undefined)
          const last = routine.routine[routine.routine.length - 1];
          if (level.reps_commit.length == isLastRepLogged.length + 1 && last.id === level.id) {
            this.checkLastRep(routine,modal)
            console.log('inside if 1:', routine);
          }else if (totalLevelRepsArray.length -1 === 0) {
            console.log('inside else if 1:', routine);
            this.appState.set('LevelNumber', level.level + 1)
            this.appState.set('levelUpdated', true)
            this.getCurrentRoutine(routine,level)
            this.appState.set('showUpdatedScreens', true)
            if (routine.path_id === currentPath.id) {
              this.appState.set('isLevelCommited',false);
              this.appState.set("allowScreenCount", undefined)
              this.appState.set('fromTodoWidget', true)
              if (this.isMobile) {
                this.route.navigate(['/content', routine.schedule_day], { queryParams: { startFromScreen: 'daily-content-screen-routine' } })                
              } else {
                // this.modalController.dismiss();
                setTimeout(() => {
                  this.openNextComponent(DailyStepsContentModalComponent, { currentDay: routine.schedule_day, startFromScreen: 'daily-content-screen-routine' }, 'web-daily-steps-custom-modal')
                }, 1100);
              }
              // this.route.navigate(['/content', routine.schedule_day], { queryParams: { startFromScreen: 'daily-content-screen-routine' } })
            } else {
             setTimeout(()=>{
              this.changeSubPath(routine.path_id, routine.schedule_day)
             }, 600)
            }
          }

        },
        (error) => {
          this.spinnerService.off()
          this.toasterService.error(this.i18n.t("Server or connection issue. Please check your internet connection."));
          console.log('Error for feedback ', error)
        }
      )
    }
  }

  checkLastRep(routine: any, modal?:any) {
    // modal.dismiss(null, 'cancel');
    this.isMobile = this.appState.get('isMobile');
    this.toasterService.success(this.i18n.t('Habit Complete!'))
    setTimeout(async () => {
     
      const alert = await this.alertController.create({
         cssClass: 'custom-alert-action',
         message: this.i18n.t('Has this routine,')+" "+ routine?.routine_info?.title + " "+ this.i18n.t(", become a habit for you?"),
         
          buttons: [
            {
              text: this.i18n.t("No"),
              cssClass:'custom-alert-textcase',
              handler:()=>{
                this.makeHabit = false
                this.openAlertGiveFeedbackCommitmentComponent(routine);
              }
            },
            {
              text: this.i18n.t("Yes"),
              cssClass:'custom-alert',
              handler:()=>{
                this.makeHabit = true
                this.openAlertGiveFeedbackCommitmentComponent(routine);
              }
            }
           ],
        });
        await alert.present();
    }, 1000);
  }
  async openAlertGiveFeedbackCommitmentComponent(routine) {
    
    const modal= await this.modalController.create({
      component: AlertGiveFeedbackCommitmentComponent,
      cssClass: this.isMobile ? 'custom-modal-size' : 'web_routine_feedback_modal',
      enterAnimation: this.animationService.slideInLeftEnterAnimation,
      leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
    })
    this.setModalStack(modal);
    await modal.present();
   modal.onDidDismiss().then((res)=>{
     if(res){
      this.giveFeedback(routine)
     }
   })
  }
  giveFeedback(routine: any) {
    var step_rating = localStorage.getItem("commitmentRating1")
    var step_comment = localStorage.getItem("commitmentFeedback")
    try {
      const requestData = {
        'user_id': routine.user_id,
        'path_id': routine.path_id,
        'step_score': step_rating,
        'step_comment': step_comment,
        'routine_info_id': routine.routine_info_id
      }
      this.spinnerService.on()
      if (requestData.step_comment == '' && requestData.step_score == '') {
        this.spinnerService.off();
      } else {
        this.routineService.routineFeedback(requestData).subscribe(
          async (response) => {
            console.log('response', response)
            this.dailyStepService.getActions(routine.user_id, routine.path_id);
            this.spinnerService.off();
            if (this.makeHabit === false) {
             
              const alert = await this.alertController.create({
                 cssClass: 'custom-alert-action',
                 message: this.i18n.t('Do you want to repeat all or part of this habit to continue building the habit of')+" "+routine?.routine_info?.title,
                 
                  buttons: [
                    {
                      text: this.i18n.t("No"),
                      cssClass:'custom-alert-textcase',
                      handler:()=>{
                       
                      }
                    },
                    {
                      text: this.i18n.t("Yes"),
                      cssClass:'custom-alert',
                      handler:()=>{
                        this.appState.set('levelEdit', true)
                        this.onSubmit(routine);
                      }
                    }
                   ],
                });
                await alert.present();
            }
          },
          (error) => {
            this.spinnerService.off()
            this.toasterService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
          }
        )
      }
    } catch (o_O) {
      console.log(o_O);
    }
  }
  
  onSubmit(routine) {
    this.appState.set('showUpdatedScreens', true)
    this.appState.set('LevelNumber', 1)
    this.findLevelId(routine, 1)
    this.appState.set('hidePrevScreen', true);
    this.appState.set("allowScreenCount", undefined)
    this.dismissAllModals().then(()=>{      
      if(this.appState.get('isMobile')) {
        this.route.navigate(['/content', routine.schedule_day], { queryParams: { startFromScreen: 'daily-content-screen-routine-levels' } })
      }
      else {
        this.modalController.dismiss()
        this.openDailyStepsComponent(DailyStepsContentModalComponent,{currentDay: routine.schedule_day,startFromScreen: 'daily-content-screen-routine-levels'},'web-daily-steps-custom-modal')
      }
    })
  }

  findLevelId(routine: any, checkedLevel: any) {
    let someArray = []
    let level_ids = [];
    routine.routine.forEach(element => {
      if (element.id != checkedLevel.id) {
        someArray.push(element)
        level_ids.push(element.id)
      }
    });
    this.appState.set('LevelNumber', someArray[0].level)
    this.appState.set('indexOfLevel', undefined)
    this.appState.set('currentLevel', someArray[0])
    this.appState.set('levelUpdated', undefined)
    const repsRequestData = {
      user_id: routine.user_id,
      is_reset: true,
      level_id: JSON.stringify(level_ids),
      routine_info_id: routine.routine_info_id
    }

    if (repsRequestData.user_id == 0) {
      return true;
    } else {
      this.routineService.appendRepsCount(repsRequestData).subscribe((res) => {
        console.log("response for reps", res);
      })
      this.routineService.editRoutine(repsRequestData).subscribe(
        (response) => {
          this.appState.set('levelUpdated', undefined)
        },
        (error) => {
          console.log('Error for edit routines', error)
        })
    }

  }

  getCurrentRoutine(routine, level) {
    let unCheckedLevels = [];
    let selectedLevels = []
    let currentPath = this.appState.get('currentPath')
    currentPath.routineInfo.forEach(currentRoutine => {
      if (currentRoutine.id === routine.routine_info_id) {
        currentRoutine.levels.forEach(routineLevel => {
          routine.routine.forEach(myLevel => {
            const match = routine.routine.filter((level) => level.id === routineLevel.id)
            if (myLevel.id !== routineLevel.id && (match.length === 0)) {
              unCheckedLevels.push({ 'id': routineLevel.id, 'index': currentRoutine.levels.indexOf(routineLevel) })
            }
          });
        });
        unCheckedLevels = [...new Set(unCheckedLevels)];
      }
      this.appState.set('unCheckedLevels', [...new Set(unCheckedLevels)])
      let unselectedIndex = routine.routine.findIndex(item => item.id === level.id);
      this.appState.set('indexOfLevel', unselectedIndex + 1)
      this.appState.set('levelUpdated', true)
    });
    routine.routine.forEach(element => {
      selectedLevels.push({ 'id': element.id, 'index': routine.routine.indexOf(element) })
    });
    selectedLevels = [...new Set(selectedLevels)]
    this.appState.set('selectedLevelIds', selectedLevels)
  }

  refresh() {
    return new Promise(resolve => {
      // here to fetch the data and rerender the contents.
      this.appState.refreshMainComponent.emit()
      setTimeout(resolve, 0)
    })
  }

  changeSubPath(pathId: number, routineDay: number) {
    this.isMobile = this.appState.get('isMobile');
    const request = {
      'user_id': this.appState.get('currentUser').id,
      'active_path_id': pathId
    }

    this.homev5Service.subpathchange(request).subscribe(
      (response) => {
        this.appState.set('isAllPaths', false)
        this.appState.set("screensViewed", undefined);
        this.appState.set('stepsArray', undefined)
        this.appState.set('stepObject', undefined)
        this.refresh()
        let data = response["data"];
        try {
          let isOnboardContentPresent = data.onboardingSurvey || data.isIntroVideo;
          if (data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted?.length > 0 && isOnboardContentPresent) {
            let value = data['isonboardingcompleted'][0];
            this.isonboardingcompleted = value["isonboardingcompleted"];
            if ((this.isonboardingcompleted === false || this.isonboardingcompleted === null) && isOnboardContentPresent) {
              this.route.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          

            } else if (this.isonboardingcompleted === true) {
              this.appState.set("allowScreenCount", undefined)
             if(this.isMobile) {
                this.route.navigate(['/content', routineDay], { queryParams: { startFromScreen: 'daily-content-screen-routine' } })
              }
              else {
              setTimeout(()=>{
                this.openDailyStepsComponent(DailyStepsContentModalComponent,{currentDay: routineDay,startFromScreen: 'daily-content-screen-routine'},'web-daily-steps-custom-modal')
              },1200);
              }            }
          } else if(data?.isonboardingcompleted.constructor === Array && data?.isonboardingcompleted.length===0 && isOnboardContentPresent){
              this.route.navigate(["/onboarding"], { state: { onboardingSurvey: data.onboardingSurvey, isIntroVideo: data.isIntroVideo } });                          
          } else {
            this.appState.set("allowScreenCount", undefined)
            this.checkSubpathStatus();
            console.log("entering final else block");
            if(this.isMobile) {
              this.route.navigate(['/content', routineDay], { queryParams: { startFromScreen: 'daily-content-screen-routine' } })
            }
            else {
              setTimeout(()=>{
                this.openDailyStepsComponent(DailyStepsContentModalComponent,{currentDay: routineDay,startFromScreen: 'daily-content-screen-routine'},'web-daily-steps-custom-modal')
              },1200);
            }
          }
        } catch (error) {
          this.route.navigate(['/main']);
        }
        this.refresh()
      }, (error) => {
        console.log("Response of path changed ", error)
      }
    )
  }

  async expandOnEnterAnimation() {
    const animation = this.animationCtrl.create()
      .addElement(document.getElementById('content'))
      .duration(200)
      .keyframes([
        { offset: 0, transform: 'scale(0)' },
        { offset: 1, transform: 'scale(1)' }
      ]);

    await animation.play();
  }
  getModalStack() {
    return this.modalStack;
  }
  getLastModal() {
   return this.modalStack.pop();
  }
  async dismissTopModal() {
    this.modalStack =this.getModalStack();
    if (this.modalStack.length > 0) {
      // Dismiss the top modal in the stack
      const modal = this.modalStack.pop();
      await modal.dismiss();
    }
  }
saveManagerEmailAddress(answers,managerEmail,currentDay?) {
     this.surveyService.saveAnswers(answers,managerEmail,currentDay).subscribe(
      (result) => {
         console.log("result", result);
      }
    );
  }
  async openNextComponent(comp?, compProps?,className?){
 
    if(comp) {
      const modal = await this.modalController.create({
        component: comp,
        componentProps: compProps,
        // animated: false,
        cssClass: className?className:'custom-modal',
        enterAnimation: this.animationService.slideInLeftEnterAnimation,
        leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
      });
       await modal.present() 
       this.setModalStack(modal)
    }
   
   }
   async dismissAllModals() {
    // Retrieve the list of active modals and dismiss them one by one
    let activeModals;
    activeModals= this.getModalStack()
    activeModals?.forEach(async (modal) => {
      await modal.dismiss();
    });
  }

  public updateDates(data): Observable<any>{
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    return this.httpclient.put(this.API_URL+this.UPDATE_DATES, data,{headers});
  }

  public fetchDailyStepsStatus(data) : Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    const params = new HttpParams().append('user_id', data.user_id)
                                   .append('path_id', data.path_id);
    return this.httpclient.get(this.API_URL + this.GET_DAILY_STEPS_STATUS, { headers, params }).pipe(
      map(response => {
        return response
      })
    )
    
  }
  
  getDailyStepsInfo(){
    this.currentUser = this.appState.get('currentUser')
    this.path = this.appState.get('currentPath')
    const requestData = {
      'user_id': this.currentUser?.id,
      'path_id': this.path?.id
    }
    this.fetchDailyStepsStatus(requestData).subscribe((data)=>{
     
      data?.data?.forEach((step)=>{
        if(step?.stepStatus =='Review' && step?.step <this.path?.dailySteps?.length)
        {
          this.appState.set('scheduleDayForCurrentStep',  step?.step+1);
        }
      })
      this.setRelationshipState(data.data);
      this.appState.set("DailyStepsData", data.data);
    })
  }

  setRelationshipState(val) {
    this.apiStepDataSubject.next(val);
  }
  getAllRoutines(userId: number, pathId: number) {
    const requestData = {
      user_id: userId,
      path_id: pathId,
    };
    this.dailyStepService.getActionsList(requestData).subscribe(
      (response) => {

        this.appState.set('todoRoutines', this.homev5Service.removeDuplicates(response.data[0].routine, "routine_info_id"))
        this.appState.set('todoActions', response.data[0].action)

        const routineList = this.homev5Service.removeDuplicates(
          response.data[0].routine,
          "routine_info_id"
        );
        // this.cdRef.detectChanges();
        var notificationSentArrayLevel = routineList.map((item) => {
          return {
            ...item,
            routine: item.routine.filter(
              (routine) => routine.notification_sent === true
            ),
          };
        });
        var notificationSentArrayLevelFinal = notificationSentArrayLevel.filter(
          (item) => item.routine.length > 0
        );
        if (
          Array.isArray(notificationSentArrayLevelFinal) &&
          notificationSentArrayLevelFinal.length > 0
        ) {
          this.counterId = this.appState.get("counterId");
          this.showDialog(
            notificationSentArrayLevelFinal,
            this.appState.get("counterId")
          );
        }
      },
      (error) => {
        this.toasterService.error(this.i18n.t(
          "Server or connection issue. Please check your internet connection."
        ));
        console.log("error :", error);
      }
    );
  }

  showDialog(notificationSentArrayLevelFinal: any[], routineLevel) {
    try {
      if (routineLevel >= notificationSentArrayLevelFinal.length) return; //finished processing

      var lastLevel =
        notificationSentArrayLevelFinal[
          notificationSentArrayLevelFinal.length - 1
        ].routine[0];
      var firstLevel = notificationSentArrayLevelFinal[routineLevel].routine[0];

      const isAfterDay = moment().isAfter(firstLevel.end_date);
      if (isAfterDay) {
        const dialogRef = this.dialog.open(AlertNotificationUpdateComponent, {
          data: {
            dataKey: { routine: firstLevel },
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result === "confirm") {
            const dialogRef = this.dialog.open(AlertReviewRoutineComponent, {
              data: {
                dataKey: { routine: firstLevel },
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (lastLevel.id === firstLevel.id) {
                this.appState.set("isNotificationViewed", true);
              }
              //this.appState.set('isNotificationViewed',true)
              var routineAnswer = firstLevel.routine_description;
              if (result === "confirm") {
                //yes
                if (firstLevel) {
                  if (firstLevel.isSingleEvent === null) {
                    return;
                  }
                  if (firstLevel.isSingleEvent) {
                    this.openRoutineCalendar(
                      false,
                      routineAnswer,
                      firstLevel,
                      notificationSentArrayLevelFinal,
                      routineLevel
                    ); //false
                  } else {
                    this.openRoutineCalendar(
                      true,
                      routineAnswer,
                      firstLevel,
                      notificationSentArrayLevelFinal,
                      routineLevel
                    ); //TODO ;  true
                  }
                  this.appState.set("isConfirmed", true);
                }
              } else if (result === "change") {
                //No, change cue
                this.appState.set("changeCue", true);
                this.appState.set("isRoutineEdited", true);
                this.counterId++;
                this.appState.set("counterId", this.counterId);
                this.route.navigate(
                  [
                    "/content",
                    notificationSentArrayLevelFinal[routineLevel].schedule_day,
                  ],
                  {
                    queryParams: {
                      startFromScreen: "daily-content-screen-meet-cue",
                    },
                  }
                );
              } else if (result === "turnOff") {
                //No, turn off reminders
                this.turnOffNotifications(
                  notificationSentArrayLevelFinal[routineLevel],
                  firstLevel,
                  notificationSentArrayLevelFinal,
                  routineLevel
                );
              }
            });
          }
        });
      }
    } catch (error) {
      this.toasterService.error(this.i18n.t(
        "Server or connection issue. Please check your internet connection."
      ));
      console.log("Error for routines ", error);
    }
  }
  openRoutineCalendar(
    isSingleEvent: boolean,
    subRoutineAnswer: any,
    level: any,
    notificationSentArrayLevelFinal: any,
    routineLevel: any
  ) {
    var data = {
      text: level.text,
      title: level.title,
      routineAnswer: subRoutineAnswer,
      level: level,
      isSingleEvent: isSingleEvent,
      routineTime: level.routine_time,
      selectedDays: level.day,
    };

    this.appState.set("routineCalendarData", data);
    this.appState.set("isRoutineCalender", true);

    var differentTimes = level.routine_time;
    var differentDays = level.day;

    const dialogRef = this.dialog.open(WeekendCalendarComponent, {
      maxWidth: "100vw",
      maxHeight: "100vh",
      height: "100%",
      width: "100%",
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.appState.set("isRoutineCalender", false);

      const dialogAnim = this.dialog.open(AlertAnimationComponent);
      dialogAnim.afterClosed().subscribe((result) => {
        this.appState.set("counterId", this.counterId++);

        differentTimes.forEach((element) => {
          const date = moment().format("YYYY/MM/DD");
          const time = element;
          const timeAndDate = moment(date + " " + time);
          timeAndDate.format("YYYY/MM/DD hh:mm A");
          console.log(timeAndDate.format("YYYY/MM/DD hh:mm A"));
          let newUtcDate = moment(timeAndDate).utc().format("hh:mm A");
          this.selectedTimings.push(newUtcDate);
        });

        let day = "{" + level.day + "}";
        let routineTime = "{" + this.selectedTimings + "}";

        const data = {
          user_id: notificationSentArrayLevelFinal[routineLevel].user_id,
          path_id: notificationSentArrayLevelFinal[routineLevel].path_id,
          routine_info_id: level.routine_info_id,
          type_level: notificationSentArrayLevelFinal[routineLevel].type_level,
          routine_time: routineTime,
          day: day,
        };

        this.homev5Service.routineUpdateData(data).subscribe(
          (response) => {
            console.log("commit response", response);
          },
          (error) => {
            console.log("error", error);
            this.toasterService.error(this.i18n.t(
              "Server or connection issue. Please check your internet connection."
            ));
          }
        );

        this.selectedTimings = [];
        this.showDialog(notificationSentArrayLevelFinal, this.counterId); //Recursively open next dialog
      });
    });
  }
  turnOffNotifications(
    routine: any,
    level: any,
    notificationSentArrayLevelFinal: any,
    routineLevel: any
  ) {
    const requestData = {
      user_id: this.currentUser.id,
      path_id: this.path.id,
      notification_sent: false,
      routine_info_id: routine.routine_info_id,
    };
    this.homev5Service.disableNotification(requestData).subscribe(
      (response) => {
        const dialogRef = this.dialog.open(AlertConfirmNotificationComponent, {
          data: {
            dataKey: { level: level },
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          this.appState.set("counterId", this.counterId++);
          this.showDialog(notificationSentArrayLevelFinal, this.counterId); //Recursively open next dialog
        });
      },
      (error) => {
        this.toasterService.error(this.i18n.t(
          "Server or connection issue. Please check your internet connection."
        ));
      }
    );
  }

  async openDailyStepsComponent(comp?, compProps?,className?){
 
    if(comp) {
      const modal = await this.modalController.create({
        component: comp,
        componentProps: compProps,
        //animated: false,
        cssClass: className?className:'custom-modal',
         enterAnimation: this.animationService.slideInUpEnterAnimation,
         leaveAnimation: this.animationService.slideOutDownLeaveAnimation
      });
      await modal.present() 
      modal.onWillDismiss().then((data) => {
         if (data?.data?.nextHabitUnlockFromToDoScreen == 'nextHabitUnlockFromToDoScreen') {
           setTimeout(() => {
             this.openDailyStepsComponent(DailyStepsContentModalComponent, { currentDay: data?.data?.currentDay, startFromScreen: 'daily-content-screen-todo' }, 'web-daily-steps-custom-modal');
           }, 600);
         }
         if(data?.data?.isInstepTodoScreen) {
          this.unlockNextHabitLevel();
         }
      
         if (data?.data?.isAlertUnlockClosed) {
            this.openDailyStepsComponent(DailyStepsContentModalComponent,{currentDay: data?.data?.currentDay,startFromScreen: 'daily-content-screen-routine'},'web-daily-steps-custom-modal')
         }
      })
    }
   
  }
  
  unlockNextHabitLevel() {
    let dailyStepsArray= this.appState.get("DailyStepsData");
    let levelUpdateData = this.appState.get("levelUpdateData");
    let currentDay = this.appState.get('currentScheduleDay')

    let allLevelsCompleted = this.appState.get("allLevelsCompleted");
    if (levelUpdateData?.levelUpdated === true) {
      this.appState.set("LevelNumber", levelUpdateData?.level.level + 1);
      const last =
        levelUpdateData?.routine.routine[
          levelUpdateData?.routine.routine.length - 1
        ];
      var isLastRepLogged = levelUpdateData?.level.reps_commit.filter(
        (item) => item.is_completed === true
      );

      if (
        levelUpdateData &&
        levelUpdateData.level.reps_commit.length ==
          isLastRepLogged.length + 1 &&
        last.id === levelUpdateData.level.id
      ) {
        if (allLevelsCompleted === true) {
          console.log("do nothing :");
        } else {
          setTimeout(()=>{
            this.checkLastRep(levelUpdateData.routine);
          },600)
        }
      } else {
        this.appState.set("levelUpdated", true);
        setTimeout(()=>{
          this.openDailyStepsComponent(AlertUnlockLevelComponent,{nextLevelData:levelUpdateData.routine.schedule_day},'web-alert-unlock-custom-modal')
        },600);
        
        this.appState.set("levelUpdateData", undefined);
      }
    }

    let levelSaveData = this.appState.get("levelSaveData");
    if (levelSaveData && dailyStepsArray[currentDay]?.stepStatus=='Review') {
      localStorage.setItem(
        `Toggle:Day:${levelSaveData.currentDay}:LevelNumber:${levelSaveData.levelId}:true`,
        "true"
      );
      this.appState.set("showUpdatedScreens", undefined);
      this.appState.set("levelSaveData", undefined);
    }
   }

  closeAllModals(currentDay) {
    // close background modals and opens daily steps modal for web
    this.modalController.dismiss()
    setTimeout(() => {
      this.modalController.dismiss()
    }, 550);

    setTimeout(() => {
      this.openNextComponent(
        DailyStepsContentModalComponent,
        { currentDay: currentDay },
        'web-daily-steps-custom-modal')
    }, 1100);
  }
  checkSubpathStatus() {
    let currentScreenId = 'no-onboarding-screen';
    this.currentUser = this.appState.get("currentUser");
    this.path = this.appState.get("currentPath");
    this.currentClient = this.appState.get("currentClient");
    const request = {
      user_id: this.currentUser.id,
      active_path_id: this.currentUser.activePathId,
      isonboardingcompleted: true,
    };

    this.homev5Service.onbordingCompleted(request).subscribe(
      (response) => {
        this.analyticsService.setOnboardingEvent('Onboarding', 'Onboarding completed', currentScreenId, this.path.id, this.currentClient.id)
      },
      (error) => {
        console.log("Response of subpath status ", error);
      }
    );
  }

  getStepwiseActionPoint(data): Observable<any> {
    const headers = new HttpHeaders();
    headers.set('Authorization', localStorage.getItem('token'));
    const params = new HttpParams().append('user_id', data.user_id)
                                   .append('path_id', data.path_id)
                                   .append('scheduled_day', data.currentDay);
    return this.httpclient.get(this.API_URL + this.GET_STEP_ACTION_POINTS_DATA, { headers, params }).pipe(
      map(response => {
        return response
      })
    )
  }

  getThumbnailInitials(userDetails) {
     let userInitials;
    if (userDetails?.first_name || userDetails?.last_name) {
        userInitials = userDetails?.first_name.charAt(0).toUpperCase() + userDetails?.last_name?.charAt(0).toUpperCase()
    }
    else  if (userDetails?.firstName || userDetails?.lastName) {
        userInitials = userDetails?.firstName.charAt(0).toUpperCase() + userDetails?.lastName?.charAt(0).toUpperCase()
    }
    //leaderboard
    else if (userDetails?.name) {
      const names = userDetails?.name?.split(" ");
      if (names?.length > 1) {
        userInitials = names[0].charAt(0).toUpperCase()+names[names?.length-1].charAt(0).toUpperCase()
      }
      else {
        userInitials = names[0].charAt(0).toUpperCase();
      }
    }
    return userInitials
  }
  
  getContrastColor(hexColor: string): string {
    if (hexColor?.length === 4 && hexColor.startsWith("#")) {
      hexColor= `#${hexColor[1]}${hexColor[1]}${hexColor[2]}${hexColor[2]}${hexColor[3]}${hexColor[3]}`;
    }
    const hex = hexColor?.replace("#", "");
    let luminance;
    if (hex) {
      const r = parseInt(hex.substring(0, 2), 16);
      const g = parseInt(hex.substring(2, 4), 16);
      const b = parseInt(hex.substring(4, 6), 16);

      // Calculate luminance
       luminance = 0.299 * r + 0.587 * g + 0.114 * b;
    }
   
    // Return black for bright colors, white for dark colors
    return luminance > 186 ? "black" : "white";
  }
  
}
