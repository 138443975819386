import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import mixpanel from 'mixpanel-browser';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';
import { AlertActionCompleteComponent } from 'src/app/alert-action-complete/alert-action-complete.component';
import { AppState } from 'src/app/app.service';
import { CompletedReviewModalComponent } from 'src/app/completed-review-modal/completed-review-modal.component';
import { DailyStepsService } from 'src/app/daily-steps/daily-steps.service';
import { FullScreenAudioComponent } from 'src/app/full-screen-audio/full-screen-audio.component';
import { FullScreenVideoComponent } from 'src/app/full-screen-video/full-screen-video.component';
import { HabitListItem } from 'src/app/habit-list-modal/habit-list-modal.model';
import { HabitService } from 'src/app/habit/habit.service';
import { I18n } from 'src/app/i18n.service';
import { InoutScreen } from 'src/app/inout-screen/inout-screen.model';
import { SingleHabitInfoComponent } from 'src/app/my-commitments/single-habit-info.component';
import { FullScreenDocumentComponent } from 'src/app/my-library/full-screen-document.component';
import { HomeUiService } from 'src/app/new-design/home-ui.service';
import { NativeService } from 'src/app/services/native.service';
import { SpinnerService } from 'src/app/spinner.service';

@Component({
  selector: 'app-my-action-component',
  templateUrl: './my-action-component.component.html',
  styleUrls: ['./my-action-component.component.scss'],
})
export class MyActionComponentComponent implements OnInit, OnChanges {
  @Input() data: any = [];
  @Input() actionList;
  @Input() currentPath: any;
  @Input() scheduledDay: any;
  @Input() inoutData: InoutScreen[] = []
  @Output() onMarkDoneConfirm: any = new EventEmitter();
  @Output() onSinglePage: any = new EventEmitter();
  @Output() onChangeTab: any = new EventEmitter();
  @Output() onChecked: any = new EventEmitter();
  currentUser
  actionItem
  showtab: boolean = false;
  selectedTab: any = 0;
  habits: HabitListItem[];
  todoLable: string
  completedLabel: string
  allLabel: string
  videoFormats = ['.mp4', '.mov', '.avi', '.avchd', '.f4v', '.mkv', '.wmv','youtube.com', 'webm', 'html5', 'HTML5'];
  audioFormats = [".mp3"];
  constructor(
    private appState: AppState,
    private spinner: SpinnerService,
    private native: NativeService,
    private tostrService: ToastrService,
    private habitService: HabitService,
    private homeUIService: HomeUiService,
    private dialog: MatDialog,
    private modalCtrl: ModalController,
    private cdRef: ChangeDetectorRef,
    private i18n: I18n,
    public route: Router,
    public dailyStepService: DailyStepsService
  ) {
    this.todoLable = this.i18n.t('TO DO')
    this.completedLabel = this.i18n.t('COMPLETED')
    this.allLabel = this.i18n.t('ALL')
  }

  ngOnChanges() {
    this.currentPath = this.appState.get("currentPath");
    this.currentUser = this.appState.get("currentUser");
    this.scheduledDay = this.appState.get("scheduleDayForCurrentStep")
  }
  ngOnInit() {
    if(JSON.parse(localStorage.getItem('isCloseButtonClicked'))) {
      this.getRoutines(this.currentUser?.id, this.currentPath.id);
    }
    console.log("data", this.actionList, this.data);
    this.spinner.on();
    
    this.initData();
  }
  toggleTheme() {
    this.homeUIService.toggleTheme();
    this.cdRef.detectChanges(); 
  }
  initData() {
    if (this.data.length > 0) {
      this.getSubText();
      setTimeout(() => {
        if (localStorage.getItem('actionTab')) {
          this.selectedTab = Number(localStorage.getItem('actionTab'));
        }
        
        this.showtab = true;
        this.spinner.off();
      }, 500)

    }
  }
  nextPage(item) {
    localStorage.setItem('actionTab', this.selectedTab.toString());
    this.onSinglePage.emit({
      item: item
    })
  }
  setCheckedColor(item) {
    if (item.is_complete_now && !item.isScheduled) {
      return 'green-checked';
    } else if (!item.is_complete_now && !item.isScheduled) {
      return 'gray-checked';
    }
  }
  setAllTabCheckedColor(index) {
    if (this.data[index].isCompleted && !this.data[index].isScheduled) {
      return 'green-checked';
    } else if (!this.data[index].isCompleted && this.data[index].isScheduled) {
      return 'white-checked';
    } else {
      return 'gray-checked';
    }
  }
  changeTab(event) {
    this.selectedTab = event.index;
    let tabValue = this.selectedTab == 0 ? 'To Do' : this.selectedTab == 1 ? 'COMPLETED' : 'ALL'
    mixpanel.track('Commitments-My Action Tab Selected', {value : tabValue, data: this.data})

    // console.log('changeTabchangeTabchangeTab', this.selectedTab)
    // this.onChangeTab.emit(event);

  }
  displayText(text) {
    return text.replace(/<p><br><\/p>/gi, '');
  }
  share(item: any) {
    this.native.shareViaApp(item.title);
  }
  getSubText() {
    this.data.forEach(element => {
      this.inoutData.map((inOutItem) => {
        if (element.startDay === inOutItem.startDay && element.endDay === inOutItem.endDay) {
          element.subText = inOutItem.homepageSubtext;
        }
      });

    });

  }
  getRoutines(userId: number, pathId: number) {
    const requestData = {
      'user_id': userId,
      'path_id': pathId
    }
    this.dailyStepService.getActionsList(requestData).subscribe((response) => {
      this.cdRef.detectChanges();
      this.actionList = response.data[0].action;
      console.log("action list", this.actionList);
    }, (error) => {
      // this.toasterService.error("Server or connection issue. Please check your internet connection.");
      console.log('error :', error);
    })
  }
  openDocument(posts) {
    
      let post ;
     posts?.documents?.forEach((doc)=>{
       if(doc?.id == posts?.casualDocumentId) {
        post =doc;
       }
     })
     let doc = this.appState.get('singleDocument');
    if(post?.id == doc?.id) {
      post =this.appState.get('singleDocument')
    }

    console.log("post", post, this.actionList);
    
    console.log('casual post :', post);
    this.appState.set('singleDocument', post)
    this.appState.set('journalPost', undefined)
    if (post?.isrecommended && post?.isrecommended === true) {
      this.appState.set('isCasualDocument', true)
    }
   
    this.appState.set('journalPathName', this.currentPath.userGroup.name)
    this.appState.set('journalStepNumber', post.startDay)
    const url = post?.link;
    if (this.audioFormats.some(el => url.includes(el))) {
      console.log('Audio File');
      this.homeUIService.openNextComponent(FullScreenAudioComponent, { data: post })
    } else if (this.videoFormats.some(el => url.includes(el))) {
      console.log('Video File');
      this.homeUIService.openNextComponent(FullScreenVideoComponent, { data: post })
    } else {
      console.log('Document');
      this.homeUIService.openNextComponent(FullScreenDocumentComponent)
     
    }
  }
  async markAsDone(event, habits) {
    if (event.checked) {
      if (this.scheduledDay < habits.dayNum) {
        this.tostrService.warning(this.i18n.t("Please unlock step from homepage!"))
      } else {
        this.onChecked.emit({
          item: habits
        })
      }
    }

  }
  async initModal(habit) {
    // this.appState.set('currentDay', habit.dayNum);
    habit.isCompleted = true;
    habit.scheduledLater = false;
    let oldHabits = this.data;
    this.data = [];
    setTimeout(() => {
      this.data = oldHabits;
    }, 5);
    const modal = await this.modalCtrl.create({
      component: CompletedReviewModalComponent,
      componentProps: {currentHabit: habit},
      cssClass: this.homeUIService.isDark() ? 'dark-theme-review-modal mobile-custom-to_do_list-forms' : 'light-theme mobile-custom-to_do_list-forms',
    });

    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {

      }
    });

    return await modal.present();
  }

  findHabitbyId(collection: any, id: any): any {
    return collection.find(
      item => item.id === id
    )
  }

  setActionsList() {
    this.habits = new Array(this.currentPath.daysOfContent)
      .fill(0)
      .map((value, index) => {
        const dayNum = index + 1;
        const habit = this.currentPath.habits.find(
          item => item.startDay <= dayNum && dayNum <= item.endDay
        );
        if (habit) {
          return {
            id: habit.id,
            dayNum: habit.startDay, // LUMBA-1307
            title: habit.title,
            text: habit.text,
            casualDocumentId: habit?.casualDocumentId,
            documents: habit?.documents,
            scheduledLater: habit.confirmation < 1 && habit.scheduledLater > 0,
            confirmed: habit.confirmation > 0,
            isConvertedToRoutine: habit.isConvertedToRoutine,
            isCompleted: habit.isCompleted,
            screenId: "daily-content-screen-habit",
            startDay: habit.startDay,
            endDay: habit.endDay,
            pathName: this.currentPath.userGroup.name
          };
        }
        return {
          id: null,
          dayNum: dayNum,
          title: null,
          text: null,
          casualDocumentId: null,
          documents: null,
          scheduledLater: false,
          confirmed: false,
          isConvertedToRoutine: false,
          isCompleted: false,
          screenId: "",
          startDay: 0,
          endDay: 0
        };
      });

    this.habits.filter(
      item => item.isCompleted !== true
    )

    console.log('habits', this.habits)
  }

}
