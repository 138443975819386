import { ChangeDetectorRef, Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalController, NavParams } from '@ionic/angular';
import * as moment from 'moment';
import { AlertWebactionCalendarComponent } from 'src/app/alert-webaction-calendar/alert-webaction-calendar.component';
import { AppState } from 'src/app/app.service';
import { CalendarService } from 'src/app/calendar.service';
import { CompletedReviewModalComponent } from 'src/app/completed-review-modal/completed-review-modal.component';
import { CordovaService } from 'src/app/cordova.service';
import { DailyContentV2Component } from 'src/app/daily-content-v2/daily-content-v2.component';
import { DailyStepsService } from 'src/app/daily-steps/daily-steps.service';
import { HabitService } from 'src/app/habit/habit.service';
import { InoutScreen } from 'src/app/inout-screen/inout-screen.model';
import { HomeV5Service } from 'src/app/pages/home-v5/home-v5.service';
import { SpinnerService } from 'src/app/spinner.service';
import { WINDOW } from 'src/app/window.service';
import { ActivatedRoute, Router } from "@angular/router";
import mixpanel from "mixpanel-browser";
import { RoutineService } from 'src/app/daily-content-v2/routine.service';
import { FullScreenDocumentComponent } from 'src/app/my-library/full-screen-document.component';
import { FullScreenVideoComponent } from 'src/app/full-screen-video/full-screen-video.component';
import { FullScreenAudioComponent } from 'src/app/full-screen-audio/full-screen-audio.component';
import { HomeUiService } from 'src/app/new-design/home-ui.service';
import { SessionService } from 'src/app/session/session.service';



export interface CalendarParams {
  title: String,
  startDate: Date
  endDate: Date,
  eventLocation: String,
  notes: String,
  endEventTime: any,
  frequency: string
}

@Component({
  selector: 'app-action-reschedule',
  templateUrl: './action-reschedule.component.html',
  styleUrls: ['./action-reschedule.component.scss'],
})
export class ActionRescheduleComponent implements OnInit {

  action: any
  currentUser: any
  currentPath: any
  currentDay: number
  yesterdayHabit: any
  calendarParams: CalendarParams
  inoutScreen: InoutScreen
  hasNativeCalendar: boolean
  isMobile: boolean
  modalDataResponse: any;
  audioFormats = [".mp3"];
  videoFormats = ['.mp4', '.mov', '.avi', '.avchd', '.f4v', '.mkv', '.wmv','youtube.com', 'webm', 'html5', 'HTML5'];

  constructor(private navParams: NavParams,
    private modalController: ModalController,
    private appState: AppState,
    private cordovaService: CordovaService,
    private calendarService: CalendarService,
    private homeService: HomeV5Service,
    private dialog: MatDialog,
    private homeUIService: HomeUiService,
    private habitService: HabitService,
    private routineService: RoutineService,
    private cdRef: ChangeDetectorRef,
    private dailyStepsService: DailyStepsService,
    private modalCtrl: ModalController,
    private spinnerService: SpinnerService,
    private sessionService: SessionService,
    public router: Router,
    @Inject(WINDOW) private window: Window) { 
      
      this.currentUser = this.appState.get('currentUser')
      this.currentPath = this.appState.get('currentPath')
      this.isMobile = this.appState.get('isMobile')
      const w: any = window
      this.hasNativeCalendar = this.cordovaService.onCordova && w && w.plugins && w.plugins.calendar
    }

  ngOnInit() {
    this.action = this.navParams.get('action');
    this.currentDay = this.navParams.get('currentDay');
    this.inoutScreen = this.findForCurrentDay(this.currentPath.inoutScreens)
    this.setYesterdayHabit()
  }

  close() {
    this.modalController.dismiss();
  }

  findForCurrentDay(collection: any): any {
    return collection.find(
      item => item.startDay <= this.currentDay && this.currentDay <= item.endDay
    )
  }

  setYesterdayHabit() {
    // LUMBA-1373
    if (this.currentDay < 1) {
      return
    }
    this.currentPath.habits.sort((a, b) => a.endDay - b.endDay)
    // LUMBA-1360
    let currentHabitIndex
    let prevHabitIndex
    for (let i in this.currentPath.habits) {
      const habit = this.currentPath.habits[i]
      if (
        habit.startDay <= this.currentDay &&
        this.currentDay <= habit.endDay
      ) {
        if (!currentHabitIndex) {
          currentHabitIndex = i
        }
      } else if (
        habit.startDay <= this.currentDay &&
        habit.endDay <= this.currentDay
      ) {
        prevHabitIndex = i
      }
    }
    if (currentHabitIndex >= 0) {
      prevHabitIndex = currentHabitIndex
    } else if (!prevHabitIndex) {
      return
    }
    this.yesterdayHabit = this.currentPath.habits[prevHabitIndex] // LUMBA-1344
    console.log('yesterdayHabit :', this.yesterdayHabit);

  }

  prepareCalendarParams(title: any, msg: String, routinelen: any = null, eventlen: string = null) {
    const start = moment(new Date(), 'HH:mm')
    const end = moment(new Date(), 'HH:mm').add(10, 'minute')
    var titleOfEvent = "Daily Learning Reminder"

    if (this.currentPath.names && this.currentPath.names.titleOfEvent) {
      titleOfEvent = this.currentPath.names.titleOfEvent;
    } else {
      titleOfEvent = "Daily Learning Reminder"
    }

    this.calendarParams = {
      startDate: start.toDate(),
      endDate: end.toDate(),
      title: title, //updated title
      eventLocation: '',
      notes: msg,
      endEventTime: routinelen,
      frequency: eventlen
    }
  }

  innerHTMLToMainTitle() {
    var divMainTitle = document.createElement("DIV");
    divMainTitle.innerHTML = this.inoutScreen?.homepageSubtext.text;
    let tv_mainTitle = divMainTitle.innerText;
    return tv_mainTitle;
  }

  innerHtmlToNotesData(habit: string) {

    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);

    return cleanText + "\n\n Here is the tool you saw in the 1st90 app to help you complete this action: " + arrayOfUrls + "\n\n After you have completed this action, mark it complete in the 1st90 app by clicking here: https://stage1st90.page.link/d6o5";
  }

  createCalendarEvent() {
    const success = function (message) { console.log('Success: ' + JSON.stringify(message)) }
    const error = function (message) { console.error('Error: ' + message) }

    // create an event interactively
    const w: any = window
    const calOptions = w.plugins.calendar.getCalendarOptions()
    const calendarList = w.plugins.calendar.listCalendars(success, error);

    const daysCount = this.currentPath.daysOfContent * 2  // LUMBA-1399
    // calOptions.recurrence = 'daily' // supported are: daily, weekly, monthly, yearly
    calOptions.recurrenceInterval = 1
    calOptions.firstReminderMinutes = 0
    calOptions.recurrenceEndDate = moment(new Date(), 'HH:mm').add(daysCount, 'day').toDate()
    
    this.calendarService.scheduleEvents(
      this.calendarParams.title,
      this.calendarParams.eventLocation,
      this.calendarParams.notes,
      this.calendarParams.startDate,
      this.calendarParams.endDate,
      calOptions,
      success,
      error
    );
  }

  innerHtmlToWebAction(habit: string) {

    var arrayOfUrls = []
    var divHabitData = document.createElement("DIV");
    divHabitData.innerHTML = habit;
    let cleanText = divHabitData.innerText;

    var uri_pattern = /\b((?:[a-z][\w-]+:(?:\/{1,3}|[a-z0-9%])|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))/ig;
    arrayOfUrls = habit.match(uri_pattern);
    return cleanText + "\n\n After you have completed this action, mark it complete in the 1st90 app by clicking here: https://stage1st90.page.link/d6o5";
  }

  async habitScheduleLater(habit) {
    let posts, docLink;
      if(habit?.document?.id == habit?.casualDocumentId) {
        posts =habit.document;
      }
    let doc = this.appState.get('singleDocument');
    if(posts?.id == doc?.id) {
      posts =this.appState.get('singleDocument')
    }
    this.appState.set('singleDocument', posts)
    docLink= posts?.editedDocumentUrl?posts?.editedDocumentUrl:posts?.link;
    mixpanel.track("InSteps-Action-Reschedule Habit", {'id': habit.id, 'pathName': habit.pathName, 'isConvertedToRoutine': habit.isConvertedToRoutine, 'screenId': habit.screenId, 'title': habit.title, 'startDay': habit.startDay})
    this.prepareCalendarParams(this.innerHTMLToMainTitle(), this.routineService.innerHtmlToMobileAction(habit.text,docLink))

    if (this.hasNativeCalendar) {
      this.createCalendarEvent()
    } else {
      this.calendarParams.notes = this.routineService.innerHtmlToWebAction(habit.text,docLink)

      this.homeService.setCalendarData(this.calendarParams)
      // const dialogRef = this.dialog.open(AlertWebactionCalendarComponent)
      // dialogRef.afterClosed().subscribe((result) => {
        
      // })
      const modal = await this.modalCtrl.create({
        component: AlertWebactionCalendarComponent,
        animated: this.sessionService.isTabletDevice()?true:false,
        cssClass: this.isMobile?'custom-modal-size':'web-action-calender-modal',
       // enterAnimation: this.animationService.slideInLeftEnterAnimation,
        //leaveAnimation:this.animationService.SlideOutRightLeaveAnimation
      });
       await modal.present() 

    }

    // if (this.yesterdayHabit.scheduledLater > 0 && this.isMobile) {
    //   return false
    // }

    localStorage.setItem(
      `Toggle:User:${this.currentUser.id}:HabitScheduled:${habit.id}`,
      'yes'
    )

    this.spinnerService.on()
    this.habitService.scheduleLater(habit.id).subscribe(res => {
      console.log('res :', res);
      // LUMBA-1344
      this.spinnerService.off()
      this.cdRef.detectChanges()
      // this.close()

    })
  }

  habitConfirm(habit) {
    mixpanel.track("InSteps-Action-Mark Habit Complete", {'title': habit.title, 'startDay': habit.startDay, 'screenId': habit.screenId,'pathName': habit.pathName,'isConvertedToRoutine': habit.isConvertedToRoutine, 'id': habit.id,'casualDocumentId': habit.casualDocumentId})
  console.log("callled cfgchvjhbjkn", habit)
    if(this.isMobile){
    this.close()
   }
    var isHabitComplete = habit.confirmation
    try {
      this.dailyStepsService.removeFromSnoozed(this.yesterdayHabit.id)
      localStorage.setItem(
        `Toggle:User:${this.currentUser.id}:HabitConfirmed:${this.yesterdayHabit.id}`,
        'yes'
      )
      localStorage.removeItem(
        `Toggle:User:${this.currentUser.id}:HabitScheduled:${this.yesterdayHabit.id}`
      )
      // this.spinnerService.on()
      this.habitService.confirm(habit.id).subscribe(res => {
        // LUMBA-1344
        // this.spinnerService.off()
        this.cdRef.detectChanges()
        console.log("Habit confirmed", res);
      })
    } catch (o_0) {
      console.log('Errors ', o_0)
    }

    try {
      let todaysHabit
      if (this.currentDay != 0) {
        todaysHabit = this.findLastHabit(this.currentPath.habits, this.currentDay)
      }
      if (this.currentPath.enabledAttributes.feedback && isHabitComplete == 0) {
        this.initModal(habit)
      }
    } catch (o_O) {
      console.log(o_O);
    }
  }

  async initModal(habit) {
    if(!this.isMobile) {
      this.modalController.dismiss();
    }
    // this.appState.set('currentDay', this.currentDay)
    const modal = await this.modalController.create({
      component: CompletedReviewModalComponent,
       componentProps: { currentHabit: habit },
      cssClass: this.isMobile ? 'mobile-custom-to_do_list-forms ' : ' web_feedbackForm_modal dark-theme',
    });

    modal.onDidDismiss().then((modalDataResponse) => {
      if (modalDataResponse !== null) {
        this.modalDataResponse = modalDataResponse.data;
        this.appState.set("isCommented", false)
      }
    });

    return await modal.present();
  }

  findLastHabit(collection: any, lastDay: any): any {
    return collection.find(
      item => item.startDay <= lastDay && lastDay <= item.endDay
    )
  }

  rescheduleAction(){
    this.habitScheduleLater(this.action)
  }

  completeAction(){
    this.habitConfirm(this.action)
  }
  OpenDocumentId(){
    this.openDocument(this.action)
  }
  openDocument(post) {
    this.appState.set('singleDocument', post)
    let posts;
      if(post.document?.id == post?.casualDocumentId) {
        posts = post.document;
      }
    
    let doc = this.appState.get('singleDocument');
    if(posts?.id == doc?.id) {
      posts =this.appState.get('singleDocument')
    }
    
    this.appState.set('singleDocument', posts)
    this.appState.set('journalPost', undefined)
    if (posts.isrecommended && posts.isrecommended === true) {
      this.appState.set('isCasualDocument', true)
    }
    this.close()
    this.appState.set('journalPathName', this.currentPath.userGroup.name)
    this.appState.set('journalStepNumber', posts.startDay)
    const url = posts.link;
    if (this.audioFormats.some(el => url.includes(el))) {
      console.log('Audio File');
      mixpanel.track('InSteps-Action-Open Documents', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Audio File','post id': post.id})
      // this.router.navigate(['/audio-library'], { state: { data: posts } })
      // this.router.navigate(['/audio-library'], { state: { data: posts } })
      if(this.isMobile){
        this.homeUIService.openNextComponent(FullScreenAudioComponent, {data:posts})
      }
      else {
        this.homeUIService.openNextComponent(FullScreenAudioComponent, {data:posts},'desktop-library-custom')
      }    
    } else if (this.videoFormats.some(el => url.includes(el))) {
      console.log('Video File');
      mixpanel.track('InSteps-Action-Open Documents', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Video File','post id': post.id})
      // this.router.navigate(['/video-library'], { state: { data: posts } })
      if(this.isMobile){
        this.homeUIService.openNextComponent(FullScreenVideoComponent, {data:posts})
      }
      else {
        this.homeUIService.openNextComponent(FullScreenVideoComponent, {data:posts},'desktop-library-custom')
      }
    } else {
      console.log('Document');
      mixpanel.track('InSteps-Action-Open Documents', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Document File','post id': post.id})
      // this.router.navigate(['/detail-document'])
      if(this.isMobile){
        this.homeUIService.openNextComponent(FullScreenDocumentComponent)
      }
      else {
        this.homeUIService.openNextComponent(FullScreenDocumentComponent,'','desktop-library-custom')
      }
    }
  }

  @HostListener('click', ['$event'])
  onLinkClick(e) {
    if (e.target.tagName.toLowerCase() === 'a' && e.target.target === '_blank') {
      if (this.cordovaService.onCordova) {
        e.stopPropagation()
        e.preventDefault()
        this.cordovaService.openLinkInBrowser(e.target.href)
      } else {
        window.open(e.target.href, '_blank')
      }
    }
  }

}
