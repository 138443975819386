import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { File } from '@awesome-cordova-plugins/file/ngx';
@Component({
  selector: 'app-video-details',
  templateUrl: './video-details.component.html',
  styleUrls: ['./video-details.component.scss'],
})
export class VideoDetailsComponent implements OnInit {
  journalPosts: any;
  isMobile: any;

  constructor(private router: Router, public file: File) {
    this.journalPosts = this.router.getCurrentNavigation().extras.state.data;
  }

  ngOnInit() {
    var myVideo = document.getElementById('myVideo');
    myVideo.requestFullscreen();
  }
}

