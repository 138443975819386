import { AfterViewInit, ChangeDetectorRef, Component, DoCheck, ElementRef, OnInit, Renderer2, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IonItemSliding, ModalController, Platform } from '@ionic/angular';
import mixpanel from 'mixpanel-browser';
import { AlertSupportComponent } from '../alert-support/alert-support.component';
import { AppState } from '../app.service';
import { I18n } from '../i18n.service';
import { JournalService } from '../pages/journal/journal.service';
import { Path } from '../path/path.model';
import { SessionService } from '../session/session.service';
import { SpinnerService } from '../spinner.service';
import { ToasterService } from '../toaster.service';
import { LibraryService } from './library.service';
import { Client } from '../client/client.model';
import { AlertDeleteReflectionComponent } from '../alert-delete-reflection/alert-delete-reflection.component';
import { AnimationsService } from '../shared/animations.service';
import { FullScreenDocumentComponent } from './full-screen-document.component';
import { FullScreenVideoComponent } from '../full-screen-video/full-screen-video.component';
import { FullScreenAudioComponent } from '../full-screen-audio/full-screen-audio.component';
import { FullScreenStepComponent } from './full-screen-step.component';
import { FullScreenReflectionComponent } from './full-screen-reflection/full-screen-reflection.component';
import { HomeUiService } from '../new-design/home-ui.service';
import { HamburgerMenuComponent } from '../new-design/hamburger-menu/hamburger-menu.component';

@Component({
  selector: 'app-my-library',
  templateUrl: './my-library.component.html',
  styleUrls: ['./my-library.component.scss'],
})

export class MyLibraryComponent implements OnInit, DoCheck, AfterViewInit {
  videoFormats = ['.mp4', '.mov', '.avi', '.avchd', '.f4v', '.mkv', '.wmv','youtube.com', 'webm', 'html5', 'HTML5'];
  audioFormats = [".mp3"];
  journalPosts: any
  currentPath: Path
  isMobile: any
  refreshPosts: boolean
  selectedIndex: any
  selectedTab: string = "SAVED"
  recommended: any
  currentClient: any
  currentUser: any
  savedData;
  saved;
  search;
  Tools
  animationState = false;
  currentTab = 0;
  startTime: any
  endTime: any
  @ViewChildren('slidingItem') slidingItem: IonItemSliding[];

  constructor(private journalService: JournalService,
    private spinnerService: SpinnerService,
    private appState: AppState,
    private route: Router,
    private cdRef: ChangeDetectorRef,
    private sessionService: SessionService,
    public libraryService: LibraryService,
    public platform: Platform,
    private dialog: MatDialog,
    private modalController: ModalController,
    public i18n: I18n,
    private homeUIService: HomeUiService,
    private toasterService: ToasterService,
    private animationService: AnimationsService,
    private renderer: Renderer2, 
    private elementRef: ElementRef) {
    this.saved =this.i18n.t("SAVED");
    this.Tools = this.i18n.t("TOOLS");
    this.search = this.i18n.t("Search");
    this.currentPath = this.appState.get('currentPath')
    this.isMobile = this.appState.get('isMobile')
    this.currentClient = this.appState.get('currentClient')
    this.currentUser = this.appState.get('currentUser')
    this.savedData =this.appState.get('savedPosts');
    let tabLabel = this.appState.get('tabLabel')
    if (tabLabel === 'recommended') {
      this.selectedIndex = 1
    }else{
      this.selectedIndex = 0
    }
  }

  ngDoCheck() {
    this.currentPath = this.appState.get('currentPath')
    if(this.appState.get('isLibraryRefresh')) {
      this.getCasualDocuments(this.currentClient.id, this.currentUser.id)
      this.appState.set('isLibraryRefresh',false)
    }
    if(this.appState.get('isDocumentClosed')) {
      this.getUpdatedLibrary();
      this.appState.set('isDocumentClosed',false)
    }
  }

  ngOnInit() {
    if(this.appState.get('FirstTimeTabCheck')){
      if (this.isMobile) {
        this.spinnerService.on();        
      }
      let flag = this.sessionService.getLibraryPosts();   
        this.appState.set('FirstTimeTabCheck', undefined);
    }
  }
  closePage(){
    
    this.modalController.dismiss();
 
  }
  getUpdatedLibrary(){
    if(this.platform.is("ios") || this.platform.is("android")) {
      let tabLabel = this.appState.get('tabLabel')
      this.appState.set('prevPathData', undefined)
      if (tabLabel == 'saved') {
        this.selectedTab ='Saved'
        this.appState.set('tabLabel', 'saved')
        this.selectedIndex =0;
        this.getPostsList()
      }
      else {
        this.selectedTab ='Tools'
        this.selectedIndex = 1
        this.appState.set('tabLabel', 'recommended')
        this.getCasualDocuments(this.currentClient.id, this.currentUser.id)
      }
  
    }
    else {
      this.getPostsList()
      this.getCasualDocuments(this.currentClient.id, this.currentUser.id)
    }
  }
  ngAfterViewInit() {
    this.getUpdatedLibrary();
  }

  getPostsList() {
    if (this.isMobile) {
      this.spinnerService.on()      
    }
    this.journalService.getList().subscribe((journalPosts) => {
      this.spinnerService.off()
      this.appState.set('dontRefresh', false)
      this.journalPosts = journalPosts
      this.appState.set('savedPosts', journalPosts)
      console.log('this.journalPosts :', this.journalPosts);
      this.cdRef.detectChanges()
    },
      (error) => {
        this.spinnerService.off()
        // this.toasterService.error(this.i18n.t('Server or connection issue. Please check your internet connection.'))
        console.log('error :', error);
      })

  }
  goToQuestion() {
    var supportDialog = this.dialog.open(AlertSupportComponent);
    supportDialog.afterClosed().subscribe()
  }

  getSingleDoc(post) {
    this.appState.set('singleDocument', post.documents[0])
    this.appState.set('journalPathName', this.currentPath.userGroup.name)
    this.appState.set('journalStepNumber', post.startDay)
    this.appState.set('journalPost', post)
    this.appState.set('singleDocNote', post.text)
    let postData =post?.documents[0];
    postData.pathName=postData?.pathName? postData?.pathName: post.pathName
    postData.text =postData?.text? postData?.text: post.text
    if (postData.length >= 1) {
      const url = postData.link;
      if (this.audioFormats.some(el => url.includes(el))) {
        console.log('Audio File');
        mixpanel.track('Library-saved post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Audio File','post id': post.id})
        if (this.isMobile) {
          this.openModal(FullScreenAudioComponent, { data: postData })
        } else {
          this.openModal(FullScreenAudioComponent, { data: postData },'desktop-library-custom')
        }
      } else if (this.videoFormats.some(el => url.includes(el))) {
        console.log('Video File');
        mixpanel.track('Library-saved post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Video File','post id': post.id})
        if (this.isMobile) {
          this.openModal(FullScreenVideoComponent, { data: postData })
        } else {
          this.openModal(FullScreenVideoComponent, { data: postData },'desktop-library-custom')
        }
      } else {
        console.log('Document');
        mixpanel.track('Library-saved post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Document File','post id': post.id})
        if (this.isMobile) {
          this.openModal(FullScreenDocumentComponent)
        } else {
          this.openModal(FullScreenDocumentComponent,'','desktop-library-custom')
        }
      }
    } else {
      mixpanel.track('Library-saved post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Document File','post id': post.id})
      if (this.isMobile) {
        this.openModal(FullScreenDocumentComponent)
      } else {
        this.openModal(FullScreenDocumentComponent,'','desktop-library-custom')
      }
    }
  }

  getCasualDoc(post) {
    this.appState.set('journalPost', undefined)
    this.appState.set('singleDocument', post)
    if (post.isrecommended && post.isrecommended === true) {
      this.appState.set('isCasualDocument', true)
      mixpanel.track('Library-tools post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Casual Document', 'post id': post.id})
    }
    this.appState.set('journalPathName', this.currentPath.userGroup.name)
    this.appState.set('journalStepNumber', post.startDay)
    const url = post.link;
    if (this.audioFormats.some(el => url.includes(el))) {
      console.log('Audio File',post);
      mixpanel.track('Library-tools post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Audio File','post id': post.id})
      if (this.isMobile) {
        this.openModal(FullScreenAudioComponent, {data:post})
      } else {
        this.openModal(FullScreenAudioComponent, {data:post},'desktop-library-custom')
      }
    } else if (this.videoFormats.some(el => url.includes(el))) {
      console.log('Video File',post);
      mixpanel.track('Library-tools post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Video File','post id': post.id})
      if (this.isMobile) {
        this.openModal(FullScreenVideoComponent, {data:post})
      } else {
        this.openModal(FullScreenVideoComponent, {data:post},'desktop-library-custom')
      }
    } else {
      console.log('Document');
      if (this.isMobile) {
        this.openModal(FullScreenDocumentComponent)
      } else {
        this.openModal(FullScreenDocumentComponent,'','desktop-library-custom')
      }
      
    }

  }
  async openModal(comp, props?,className?){
    console.log("property",props)
    this.appState.set('isBackAllowed', true)
    const modal = await this.modalController.create({
      component: comp,
      componentProps:{data: props},
      // animated: false,
      cssClass: className?className:'custom-modal',
      enterAnimation: this.animationService.slideInLeftEnterAnimation,
      leaveAnimation: this.animationService.SlideOutRightLeaveAnimation
    });
    this.homeUIService.setModalStack(modal)
     await modal.present()   
  }

  getAllPosts(post) {
    this.appState.set('journalPost', post)
    this.appState.set('singleDocNote', post.text)
    mixpanel.track('Library-saved post', {'post title':post.title,'post imageurl':post.imageUrl, 'link': post.link, 'path name': post.path_name, 'post type':'Video File','post id': post.id})
    setTimeout(()=>{
      if (this.isMobile) {
        this.openModal(FullScreenStepComponent);
      } else {
        this.openModal(FullScreenStepComponent,'','desktop-library-custom');
      }
    },100);
  }

  back() {
    window.history.back()
  }

  deletePost(post) {
    if (post.reflections.length >= 1 && post.inoutScreens.length < 1) { //Deleting reflection
      const dialogRef = this.dialog.open(AlertDeleteReflectionComponent)
      dialogRef.afterClosed().subscribe((result) => {
        if (result === 'confirm') {
          this.deleteReflection(post)
        }
      })
    } else { //Deleting saved step and document
      this.spinnerService.on()
      this.libraryService.deletePost(post.id).subscribe(
        () => {
          this.spinnerService.off()
          this.toasterService.success(this.i18n.t('Post Deleted!'))
          this.getPostsList()
        },
        (error) => {
          this.spinnerService.off()
          console.log('Error for feedback ', error)
        }
      )
    }
  }

  onTabChangeEvent($event){
    this.appState.set("libraryTabChanged",true);
    this.selectedTab = $event.tab.textLabel
    if (this.selectedTab.toLowerCase() == this.saved.toLowerCase()) {
      this.appState.set('tabLabel', 'saved')
      this.selectedTab ="Saved"
      this.selectedIndex =0;
      this.getPostsList()
    }

    else {
      this.selectedIndex = 1
      this.appState.set('tabLabel', 'recommended')
      this.selectedTab ="Tools"
      this.getCasualDocuments(this.currentClient.id, this.currentUser.id)

    }
    this.currentTab = $event.index;
    this.endTime = new Date();
    let timeDiff = this.endTime - this.startTime; // time difference in milliseconds
    let seconds = Math.floor(timeDiff / 1000); // convert to seconds
    let minutes = Math.floor(seconds / 60); // convert to minutes
    let hours = Math.floor(minutes / 60); // convert to hours
    let timeSpent = hours + ":" + minutes + ":" + seconds; // format the time spent
    mixpanel.track('Library- Tab change', {'Tabs active': this.currentTab == 0 ? 'Tools' : this.currentTab == 1 ? 'Saved' : '', 'time spent': timeSpent})
    localStorage.setItem('libraryTab', this.currentTab.toString());
  }

  getCasualDocuments(clientId,userId){
    let requestData = {
      'clientId': clientId,
      'userId': userId
    }
    if (requestData) {
      if (this.isMobile) {
        this.spinnerService.on()      
      }
      this.libraryService.getCasualDocuments(requestData).subscribe(documents => {
        this.recommended = documents.data
        this.sortDataByTitle();
        this.spinnerService.off()
      })
    }
  }

  openReflection(post: any){
    this.appState.set('journalPost', post)
    if (this.isMobile) {
      this.openModal(FullScreenReflectionComponent);
    } else {
      this.openModal(FullScreenReflectionComponent, '', 'desktop-library-custom');
    }  
  }

  deleteReflection(post){
    this.spinnerService.on()
    this.libraryService.deletePost(post.id, "reflection").subscribe(
      (response) => {
        this.spinnerService.off()
        this.toasterService.success(this.i18n.t('Post Deleted!'))
        this.getPostsList()
        localStorage.setItem(
          `Reflection:User:${this.currentUser.id}:DayProgress:${this.currentPath.id}:${post.reflections[0].startDay}`,''
        )
        this.appState.get('currentPath').update('reflections', post.reflections[0].id, { text: null })
      },
      (error) => {
        this.spinnerService.off()
        console.log('Error for feedback ', error)
      }
    )
  }

  closeOpenItems(index?) {
    // Close any open sliding item
    if(this.slidingItem){
      this.slidingItem.forEach((item)=>{
       item.closeOpened();
      });
    }   

  }

  sortDataByTitle(): void {
    this.recommended.sort((a, b) => {
      const titleA = a.title.replace(/<\/?[^>]+(>|$)/g, "").toLowerCase();
      const titleB = b.title.replace(/<\/?[^>]+(>|$)/g, "").toLowerCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });
  }

}
